import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../pages/redux/userRedux';
import { Link } from 'react-router-dom';
import "./navbar.css";

const Navbar = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch("http://localhost:5000/api/auth/logout", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Logout failed");
      }
  
      localStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
  
      const resObject = await response.json();
      dispatch(logout());
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <nav className="navbar">
      <div className="nav-container">
        <Link to="/" className="nav-logo">
          <h1>Infinia</h1>
        </Link>

        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`hamburger ${isMenuOpen ? 'active' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <Link to="/Auto/caption" className="nav-item">AutoCaption</Link>
          <Link to="/image/base/video" className="nav-item">Video Creator</Link>
          <Link to="/my/profile" className="nav-item">Profile</Link>
          
          {user.currentUser ? (
            <div className="nav-item" onClick={handleLogout}>Logout</div>
          ) : (
            <Link to="/login" className="nav-item">Login or Register</Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
