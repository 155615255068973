import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { mobile } from '../responsive';
import { loginStart, loginSuccess, loginFailure } from './redux/userRedux';
import Navbar from '../component/Navbar';
import Googlepng from '../Assest/google.png';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  ),
  url("https://png.pngtree.com/background/20220716/original/pngtree-illustration-form-registration-appointment-medical-life-background-design-picture-image_1645287.jpg") center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 65%;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
  background-color: rgb(95 131 115 / 48%);
  ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 34px;
  font-weight: 600;
  color: white;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 80%;
  border: none;
  padding: 15px 20px;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const ButtonImg = styled.img`
  width: 40px;
  margin-right: 10px;
`;

const ButtonText = styled.p`
  font-weight: 600;
  margin: 0;
`;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching, error } = useSelector((state) => state.user);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch("https://api.aavelance.com/api/auth/login/success", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const data = await response.json();
          dispatch(loginSuccess(data));
          navigate('/'); // Redirect to home page or dashboard
        }
      } catch (err) {
        console.log(err);
        dispatch(loginFailure());
      }
    };

    checkLoginStatus();
  }, [dispatch, navigate]);

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    dispatch(loginStart());
    window.location.href = "https://api.aavelance.com/api/auth/login/google";
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>Login</Title>
          <Form>
            <Button onClick={handleGoogleLogin} disabled={isFetching}>
              <ButtonImg src={Googlepng} alt="Google logo" />
              <ButtonText>Sign In with Google</ButtonText>
            </Button>
            {error && <p style={{ color: 'red', marginTop: '10px' }}>Authentication failed. Please try again.</p>}
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default Login;