import './App.css';
import React from 'react';
import Login from './pages/Login';
import { useSelector } from 'react-redux';
import Userprofile from './pages/Userprofile/userprofile'
import Mainpage from "./component/Mainpage"
import Videoagent from "./pages/videoagent"
import { BrowserRouter , Routes , Navigate , Route} from "react-router-dom";
import ShortVideo from "./component/components/ShortVideo/shortvideo"
import VideoToSound from "../src/component/components/videoTosound/videoTosound"
import AI_Video_editor from "../src/component/components/AI_Video_editor/AI_Video_editor"
import AiEditor from "../src/component/AIEditorContainer/AiEditor"
import RemoveNoise from "./component/components/RemoveNoise/NoiseRemovalUI"
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import AiCreator from './component/AIEditorContainer/AiCreator';
import AavelanceWaitlist from './component/wating/AavelanceWaitlist';
import { useNavigate } from 'react-router-dom';
import CreatorImageVideo from "../src/pages/ImageBaseVideo/VideoCreator"
import VideoGenerator from './component/VideoGeneration';
import VideoEditor from "../src/component/wating/Landing1";
function App() {
  useEffect(() => {
    ReactGA.initialize('G-G2B17DK6GC');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])


  const users = useSelector(state => state.user.currentUser);
  const user = users?.user?.username;

  function RedirectComponent() {
    const navigate = useNavigate();
    
    React.useEffect(() => {
      window.location.href = 'https://infiniastudio.cloud';
    }, []);
  
    return null;
  }

  return (
    <>
    <BrowserRouter> 
    <Routes>
      {/* <Route path="/Login"element={user ? <Navigate to="/" replace={true} />: <Login/>} />  
      <Route path="/my/profile" element={user ? <Userprofile/>: <Navigate to="/login" replace={true}/>}/>
      <Route path="/" element={user ? <Mainpage/> : <RedirectComponent/>}/> 
      <Route path="/image/base/video" element={ users?.user?.verified === true ? <CreatorImageVideo/>:<Navigate to="/login" replace={true}/>}/>
      <Route path="/shortvideo" element={users?.user?.verified === true ? <ShortVideo/> :<Navigate to="/login" replace={true}/>}/>
      <Route path="/Video/Generators" element={users?.user?.verified === true ? <VideoGenerator/> :<Navigate to="/login" replace={true}/>}/>
      <Route path="/RemoveNoise" element={users?.user?.verified === true ? <RemoveNoise/> : <Navigate to="/login" replace={true}/>}/>  
      <Route path="/video/to/sound" element={users?.user?.verified === true ? <VideoToSound/> : <Navigate to="/login" replace={true}/>}/> 
      <Route path="/Auto/caption" element={users?.user?.verified === true ? <AI_Video_editor/> : <Navigate to="/login" replace={true}/>}/> 
      <Route path="/AI/Video/Editor" element={users?.user?.verified === true ? <AiEditor/>:<Navigate to="/login" replace={true}/>}/> 
      <Route path="/AI/Video/Creator" element={<AiCreator/>}/>  */}
      <Route path="/" element={<AavelanceWaitlist />}/> 
    </Routes> 
     
    </BrowserRouter>
    
    
    </>
  );
}

export default App;
