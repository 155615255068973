import React from "react";
import Waitinglist from "../wating/components/waitinglist"
import VideoEditor from "./Landing1";
const AavelanceWaitlist = () => {
  return (
    <div className="waitlist-container">
      <VideoEditor/>
      <div style={{marginTop:"0%"}}>
         <Waitinglist/>
      </div>
      <style jsx>{`
        .waitlist-container {
          align-items: center;
          justify-content: center;
          background: linear-gradient(to right, #6d1313, #eebf74);
          color: white;
          padding-top:10px;
          font-family: Arial, sans-serif;
        }
        .title {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }
        .subtitle {
          font-size: 1.25rem;
          margin-bottom: 2rem;
          margin-left:10px
          color: #cbd5e0;
        }
        .form {
          display: flex;
          margin-bottom: 1rem;
        }
        .email-input {
          padding: 0.5rem 1rem;
          font-size: 1rem;
          border: none;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          width: 250px;
        }
        .submit-button {
          padding: 0.5rem 1rem;
          font-size: 1rem;
          background-color: black;
          color: white;
          border: none;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .submit-button:hover {
          background-color: #2d3748;
        }
        .message {
          margin-top: 1rem;
          font-size: 1rem;
          color: #68d391;
        }
        .landing-page {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 2rem;
          background: linear-gradient(to right, #e74c3c, #f9d5d3);
          min-height: 100vh;
          color: white;
        }

  .content {
    flex: 1;
    max-width: 50%;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .cta-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .placeholder-image {
    width: 300px;
    height: 200px;
    background-color: #3498db;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .landing-page {
      flex-direction: column;
    }

    .content, .image-container {
      max-width: 100%;
      margin-bottom: 2rem;
    }
      `}</style>
    </div>
  );
};

export default AavelanceWaitlist;
