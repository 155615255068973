import React, { useState } from 'react';

const Download = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  
  const videos = [
    { id: 1, title: 'Video Title', from: 'English', to: 'Spanish', date: '2023-06-15', url: 'https://example.com/video1.mp4' },

  ];
  
  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };
  
  const handleClosePlayer = () => {
    setSelectedVideo(null);
  };
  
  return (
    <div className="translated-videos-container">
      <h1>Remove noise Video</h1>
      {/* <p className="subtitle">View your previously translated videos and share them with others.</p> */}
      
      <div className="video-grid">
        {videos.map((video) => (
          <div key={video.id} className="video-card" onClick={() => handleVideoClick(video)}>
            <h2>{video.title}</h2>
            <p>Translated from {video.from} to {video.to}</p>
            <div className="caption-button">
              Download
            </div>
            <p className="translation-date">
              on {video.date}
              <span className="icons">
                <span className="icon">↗</span>
                <span className="icon">↓</span>
              </span>
            </p>
          </div>
        ))}
      </div>

      {selectedVideo && (
        <div className="video-player-overlay" onClick={handleClosePlayer}>
          <div className="video-player-container" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleClosePlayer}>×</button>
            <h2>{selectedVideo.title}</h2>
            <video controls width="100%">
              <source src={selectedVideo.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <p>Translated from {selectedVideo.from} to {selectedVideo.to}</p> */}
          </div>
        </div>
      )}

      <style jsx>{`
        .translated-videos-container {
          font-family: Arial, sans-serif;
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          text-align: center;
        }
        h1 {
          font-size: 2.5em;
          margin-bottom: 10px;
        }
        .subtitle {
          color: #666;
          margin-bottom: 30px;
        }
        .video-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
        }
        .video-card {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 20px;
          text-align: left;
          cursor: pointer;
          transition: box-shadow 0.3s ease;
        }
        .video-card:hover {
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        .video-card h2 {
          margin-top: 0;
        }
        .caption-button {
          background: linear-gradient(to right, #ccc, #999);
          color: white;
          padding: 10px;
          text-align: center;
          border-radius: 5px;
          margin: 15px 0;
        }
        .translation-date {
          color: #666;
          font-size: 0.9em;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .icons {
          display: flex;
          gap: 10px;
        }
        .icon {
          cursor: pointer;
        }
        .video-player-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .video-player-container {
          background-color: white;
          padding: 20px;
          border-radius: 8px;
          max-width: 80%;
          max-height: 80%;
          overflow-y: auto;
          position: relative;
        }
        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          background: none;
          border: none;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default Download;