import React, { useState } from 'react';

const WaitlistForm = () => {
  // State hooks for name, email, and message
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(''); // Clear previous message

    try {
      const response = await fetch("https://api.infiniastudio.cloud/api/user/wating/list", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, name }), // Send name and email in request body
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Submitted data:", data);
        setMessage("Thank you for joining our waitlist!");
        setName(''); // Clear name field
        setEmail(''); // Clear email field
      } else {
        // If response is not OK, throw an error
        throw new Error("Failed to join waitlist");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Maybe you already registered this email. Please try again.");
      alert("An error occurred. Maybe you already registered this email. Please try again.")
    }
  };

  // UI rendering
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Join the Waitlist</h1>
      <p style={styles.subtitle}>
        Be the first to know when we launch. Enter your email and name to get on the list.
      </p>
      {message && <p style={styles.message}>{message}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        {/* Name input */}
        <div style={styles.inputGroup}>
          <label htmlFor="name" style={styles.label}>Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            style={styles.inputt}
            required
          />
        </div>

        {/* Email input */}
        <div style={styles.inputGroup}>
          <label htmlFor="email" style={styles.label}>Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={styles.inputt}
            required
          />
        </div>

        {/* Submit button */}
        <button type="submit" style={styles.button}>
          Join Waitlist
        </button>
      </form>
    </div>
  );
};

// CSS styles
const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '16px',
    textAlign: 'center',
    color: '#ffff',
    marginBottom: '20px',
  },
  message: {
    color: 'green',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '15px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  inputt: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    color:'white'
  },
  button: {
    backgroundColor: '#000',
    color: '#fff',
    border: 'none',
    padding: '12px',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default WaitlistForm;
