import React from 'react';
import './Landing2.css';
import image1 from "../../Assest/0_V3YXYrKbE5zARzYi.jpg"
import image2 from "../../Assest/CGI-and-VFX.png"
import image3 from "../../Assest/photo-1682056598904-9aa7ea5e8991.avif"

const Landing2 = () => {
  return (
    <div className="landing-container">
      {/* First Section */}
      <section className="video-editing-section">
        <h1 style={{color:"white"}}>AI Video Editing</h1>
        <p className="subtitlelandingpage2">
          Transform long videos into engaging short clips effortlessly with our AI-powered editor.
        </p>

        <div className="features-container">
          <div className="video-preview">
            {/* Add your video editor image here */}
            <img src={image1} alt="Video Editor Interface" />
          </div>
          
          <div className="features-list">
            <div className="feature">
              <h3 style={{color:"white"}}>AI Auto Edit</h3>
              <p>AI helps edit video mistakes, like removing repeated words, cutting unwanted scenes, and filtering inappropriate language.</p>
            </div>

            <div className="feature">
              <h3 style={{color:"white"}}>Auto-Captions Feature</h3>
              <p>Add stylish auto-captions to your videos with just a few clicks using AI.</p>
            </div>

            <div className="feature">
              <h3 style={{color:"white"}}>Custom Content Creation</h3>
              <p>Generate unique videos tailored to your needs using multiple AI video generation models.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Second Section */}
      <section className="vfx-section">
        <h2>AI-Powered VFX</h2>
        <p className="subtitle">
          Transform your videos with stunning visual effects powered by advanced AI technology for cinematic quality.
        </p>

        <div className="vfx-cards">
          <div className="vfx-card">
            <img src={image2} alt="VFX Creation Interface" />
            <div className="card-content">
              <h3>Effortless VFX Creation</h3>
              <p>Reduce production time with AI-assisted tools that simplify the creation of breathtaking visual effects.</p>
            </div>
          </div>

          <div className="vfx-card">
            <img src={image3} alt="Custom VFX Solutions" />
            <div className="card-content">
              <h3>Custom VFX Solutions</h3>
              <p>Create unique visual effects tailored to your content, enhancing storytelling and audience engagement effortlessly.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing2;
