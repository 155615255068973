import React from "react";
import "./VideoEditor.css";
import part1 from "./images/photo-1619097647550-0fe7f031c18b (1).avif";
import part2 from "./images/Screenshot 2024-12-01 223658.png";
import Landing2 from "./Landing2";
import WaitlistForm from "./components/waitinglist";
import Navbar from "../Navbar";
const VideoEditor = () => {
  return (
    <div className="topLandingpage">
      <Navbar />
      <div className="video-editor">
        {/* Hero Section */}
        <div className="hero-section">
          <div className="hero-content">
            <h1 style={{ color: "black" }}>Empowering Creativity with AI</h1>
            <p className="headerPtagLanding1">
              Transforming long videos into captivating clips effortlessly with
              our innovative AI-powered video editor.
            </p>
            <button className="start-btn">Coming soon</button>
          </div>
          <div className="hero-image">
            <div className="editor-screen">
              <img src={part1} alt="" />
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="services-section">
          <h1 style={{ color: "white" }}>Video Generation Services</h1>
          <p className="services-sectionPtag">
            Create stunning videos from text or enhance existing ones with our
            AI-powered solutions.
          </p>

          <div className="services-container">
            <div className="services-demo">
              <img
                src={part2}
                style={{ objectFit: "contain", width: "100%", height: "40vh" }}
                alt=""
              />
            </div>

            <div className="services-list">
              <div className="service-item">
                <h3 style={{ color: "white" }}>Custom Video Solutions</h3>
                <p style={{ fontSize: "14px", width: "70%" }}>
                  Choose from multiple models tailored to your specific video
                  generation needs.
                </p>
              </div>

              <div className="service-item">
                <h3 style={{ color: "white" }}>Text-to-Video Creation</h3>
                <p style={{ fontSize: "14px", width: "70%" }}>
                  Transform your text descriptions into engaging videos using
                  advanced AI models.
                </p>
              </div>

              <div className="service-item">
                <h3 style={{ color: "white" }}>Video Enhancement Tools</h3>
                <p style={{ fontSize: "14px", width: "70%" }}>
                  Elevate your existing videos with AI-generated content for a
                  fresh perspective.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Landing2 />
      </div>
    </div>
  );
};

export default VideoEditor;
